require('./bootstrap');

/*

Main javascript functions to init most of the elements

#1. CHAT APP
#2. CALENDAR INIT
#3. FORM VALIDATION
#4. DATE RANGE PICKER
#5. DATATABLES
#6. EDITABLE TABLES
#7. FORM STEPS FUNCTIONALITY
#8. SELECT 2 ACTIVATION
#9. CKEDITOR ACTIVATION
#10. CHARTJS CHARTS http://www.chartjs.org/
#11. MENU RELATED STUFF
#12. CONTENT SIDE PANEL TOGGLER
#13. EMAIL APP
#14. FULL CHAT APP
#15. CRM PIPELINE
#16. OUR OWN CUSTOM DROPDOWNS
#17. BOOTSTRAP RELATED JS ACTIVATIONS
#18. TODO Application
#19. Fancy Selector

*/

// ------------------------------------
// HELPER FUNCTIONS TO TEST FOR SPECIFIC DISPLAY SIZE (RESPONSIVE HELPERS)
// ------------------------------------

function is_display_type(display_type) {
    return ($('.display-type').css('content') == display_type) || ($('.display-type').css('content') == '"' + display_type + '"');
}

function not_display_type(display_type) {
    return ($('.display-type').css('content') != display_type) && ($('.display-type').css('content') != '"' + display_type + '"');
}

$(function () {

    // #1. CHAT APP
    //
    // $('.floated-chat-btn, .floated-chat-w .chat-close').on('click', function(){
    //   $('.floated-chat-w').toggleClass('active');
    //   return false;
    // });
    //
    //
    // $('.message-input').on('keypress', function(e){
    //   if (e.which == 13) {
    //     $('.chat-messages').append('<div class="message self"><div class="message-content">' + $(this).val() + '</div></div>');
    //     $(this).val('');
    //     var $messages_w = $('.floated-chat-w .chat-messages');
    //     $messages_w.scrollTop( $messages_w.prop( "scrollHeight" ) );
    //     $messages_w.perfectScrollbar('update');
    //     return false;
    //   }
    // });
    //
    // $('.floated-chat-w .chat-messages').perfectScrollbar();


    // #2. CALENDAR INIT
    // #3. FORM VALIDATION
    // #4. DATE RANGE PICKER
    // #5. DATATABLES
    // #6. EDITABLE TABLES
    // #7. FORM STEPS FUNCTIONALITY
    // #8. SELECT 2 ACTIVATION
    // #9. CKEDITOR ACTIVATION
    // #10. CHARTJS CHARTS http://www.chartjs.org/
    // #11. MENU RELATED STUFF

    // INIT MOBILE MENU TRIGGER BUTTON
    $('.mobile-menu-trigger').on('click', function () {
        $('.menu-mobile .menu-and-user').slideToggle(200, 'swing');
        return false;
    });

    // INIT MENU TO ACTIVATE ON HOVER
    var menu_timer;
    $('.menu-activated-on-hover ul.main-menu > li.has-sub-menu').mouseenter(function () {
        var $elem = $(this);
        clearTimeout(menu_timer);
        $elem.closest('ul').addClass('has-active').find('> li').removeClass('active');
        $elem.addClass('active');
    });
    $('.menu-activated-on-hover ul.main-menu > li.has-sub-menu').mouseleave(function () {
        var $elem = $(this);
        menu_timer = setTimeout(function () {
            $elem.removeClass('active').closest('ul').removeClass('has-active');

        }, 200);
    });

    // INIT MENU TO ACTIVATE ON CLICK
    $('.menu-activated-on-click li.has-sub-menu > a').on('click', function (event) {
        var $elem = $(this).closest('li');
        if ($elem.hasClass('active')) {
            $elem.removeClass('active');
        } else {
            $elem.closest('ul').find('li.active').removeClass('active');
            $elem.addClass('active');
        }
        return false;
    });

    // INIT MENU TO ACTIVE WHEN CURRENT URL
    $('.sub-menu .active').parents('.has-sub-menu').addClass('active').attr('data-active', 'true');

    // #12. CONTENT SIDE PANEL TOGGLER

    $('.content-panel-toggler, .content-panel-close, .content-panel-open').on('click', function () {
        $('.all-wrapper').toggleClass('content-panel-active');
    });


    // #13. EMAIL APP
    // #14. FULL CHAT APP
    // #15. CRM PIPELINE
    // #16. OUR OWN CUSTOM DROPDOWNS
    $('.os-dropdown-trigger').on('mouseenter', function () {
        $(this).addClass('over');
    });
    $('.os-dropdown-trigger').on('mouseleave', function () {
        $(this).removeClass('over');
    });
    // #17. BOOTSTRAP RELATED JS ACTIVATIONS
    // #18. TODO Application
    // #19. Fancy Selector

});
